
import { defineComponent } from "vue";
import CreateResponseForm from "@/views/settings/constituent/CreateResponseForm.vue";

export default defineComponent({
  name: "CreateCoinType",
  components: {
    CreateResponseForm,
  },
  setup() {
    return {};
  },
});
