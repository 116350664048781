<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="response" class="form-label">Response</label>
                <InputWrapper :errors="formError.response">
                  <textarea
                    rows="3"
                    type="text"
                    v-model="formData.response"
                    class="form-control"
                    id="response"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Response</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>
  
  <script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateResponseForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      response: null,
    });
    const formError = ref({});
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/misc/automated/response`, formData.value);
        ShowSuccess("Response Created Successfully", () => {
          formData.value = {
            response: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create coin: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
</script>
  
  <style scoped></style>
  