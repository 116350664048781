<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Create a New Response</h1>
    </div>
    <CreateResponseForm />
  </section>
</template>
    
    <script lang="ts">
import { defineComponent } from "vue";
import CreateResponseForm from "@/views/settings/constituent/CreateResponseForm.vue";

export default defineComponent({
  name: "CreateCoinType",
  components: {
    CreateResponseForm,
  },
  setup() {
    return {};
  },
});
</script>
    
    <style scoped></style>
    