<template>
  <div class="position-relative">
    <div v-if="loading" class="loader-container" @click.stop>
      <div class="d-flex justify-content-center">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "DualRingLoader",
  props: ["loading"]
};
</script>

<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(187, 183, 183, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
